.nav-link {
    color: #3C4A5B;
    text-decoration: none;
}

.nav-link-cta {
    padding: 12px;
    background-color: #1A4BEA;
    color: #FFF !important;
    border-radius: 4px;
    font-family: 'Bahnschift Light';
    text-align: center;
    width: 120px;
    display: inline;
    cursor: pointer;
    margin: 0px 5px;
    text-decoration: none;
    font-weight: 600;
}

.nav-link-cta-sec {
    padding: 12px;
    background-color: #FFF;
    color: #1A4BEA;
    border-radius: 4px;
    border: 1px solid #1A4BEA;
    text-align: center;
    width: 120px;
    display: inline;
    cursor: pointer;
    margin: 0px 5px;
    text-decoration: none;
    font-weight: 600;
}

.selected-link {
    color: #3C4A5B;
    font-weight: bolder;
    text-decoration: none;
}

.nav-link-active {
    color: #3C4A5B;
    text-decoration: none;
    font-weight: 600;
    border: 2px solid #f2aa00;
    border-radius: 24px;
    padding: 2px 18px 4px;
}