.react-datepicker-wrapper{
    width: 100%;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    font-size: 13px;
    text-align:left;
    display: block;
    height: 30px;
    border: none;
    width: 100%;
    border-bottom: 1px solid #3c4a5b;
    font-weight: 400;
    outline: none;
}