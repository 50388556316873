#container-historial-transacciones {
  width: 100%;
  box-sizing: border-box;
  min-height: 200px;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  border-radius: 5px;
  color: #3C4A5B;
  font-size: 13px;
  .title {
    width: 100%;
    color: #3C4A5B;
    text-align: center;
    text-indent: -9999px;
    font-weight: 400;
    margin-top: 20px;
  }

  @media only screen and (max-width: 600px) {
    .container-table-phone {
      width: 100%;
      margin-top: 20px;
    }

    .row-transaccion-header-desktop {
      display: none;
    }

    .row-transaccion-desktop {
      display: none;
    }

    .row-transaccion-phone {
      width: 100%;
      height: 40px;
      background-color: rgba(255, 255, 255, 0.1);
      margin: 1px;
      text-align: center;
    }

    .column-header-phone {
      background-color: rgba(243, 163, 6, 0.5);
      text-align: left;
      padding-left: 10px;
      width: 30%;
    }
  }

  @media only screen and (min-width: 600px) {

    .container-table-phone {
      display: none;
    }

    .row-transaccion-header-desktop {
      width: 100%;
      height: 40px;
      background-color: rgba(243, 163, 6, 0.5);
      margin: 1px;
      text-align: center;
    }

    .row-transaccion-desktop {
      width: 100%;
      height: 40px;
      background-color: rgba(255, 255, 255, 0.1);
      text-align: center;
    }

    .row-transaccion-phone {
      display: none;
    }

    .column-header-phone {
      display: none;
      width: 30%;
    }
  }
}
