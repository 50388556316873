.customModal {
  /* background: #b2dbbf; */
  max-width: 800px;
  width: 800px;
  height: auto;
  padding: 40px !important;
}
@media (max-width: 350px) {
  .customModal {
    padding: 20px !important;
  }
}
