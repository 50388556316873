.swiper {
  width: 100%;
  padding-bottom: 21px;
}
.swiperRegularCards {
  /* padding-bottom: 21px; */
  height: 371px;
}
.swiperPremiumCards {
  height: 220px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
.swiper-slide.swiper-slide-visible.swiper-slide-active {
  overflow: visible !important;
}

.swiper-button-next {
  right: -57px;
}
.swiper-button-prev {
  left: -68px;
}
.swiper-pagination {
  top: 92% !important;
}

@media (max-width: 480px) {
  .swiper {
    width: 234px;
  }
  .swiperPremiumCards {
    width: 290px;
  }
  .swiper-button-next {
    display: none;
  }
  .swiper-button-prev {
    display: none;
  }
}
/* .swiper-slide:nth-child(1n) {
    background-color: rgb(206, 17, 17);
  }
  
  .swiper-slide:nth-child(2n) {
    background-color: rgb(0, 140, 255);
  }
  
  .swiper-slide:nth-child(3n) {
    background-color: rgb(10, 184, 111);
  }
  
  .swiper-slide:nth-child(4n) {
    background-color: rgb(211, 122, 7);
  }
  
  .swiper-slide:nth-child(5n) {
    background-color: rgb(118, 163, 12);
  }
  
  .swiper-slide:nth-child(6n) {
    background-color: rgb(180, 10, 47);
  }
  
  .swiper-slide:nth-child(7n) {
    background-color: rgb(35, 99, 19);
  }
  
  .swiper-slide:nth-child(8n) {
    background-color: rgb(0, 68, 255);
  }
  
  .swiper-slide:nth-child(9n) {
    background-color: rgb(218, 12, 218);
  }
  
  .swiper-slide:nth-child(10n) {
    background-color: rgb(54, 94, 77);
  } */
